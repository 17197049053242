import React from 'react'
import styled from 'styled-components'

const info = () => {
  return (
    <Wrapper>
      <h3>STATS</h3>
      <h4>
        Constructeur:{' '}
        <a href="https://www.maisons-mca.com" target="_blank" rel="noreferrer">
          {' '}
          Maisons de la Côte Atlantique
        </a>
      </h4>
      <h4>
        Financement:{' '}
        <a href="https://www.hsbc.fr/" target="_blank" rel="noreferrer">
          HSBC
        </a>
      </h4>
      <h4>
        Lieu:{' '}
        <a
          href="https://www.openstreetmap.org/?mlat=45.3549&mlon=-1.0613#map=12/45.3547/-1.0612"
          target="_blank"
          rel="noreferrer"
        >
          Vendays-Montalivet
        </a>
      </h4>
    </Wrapper>
  )
}

export default info

const Wrapper = styled.section`
  text-align: right;

  @media screen and (max-width: 600px) {
    text-align: left;
    margin-top: 2em;
    margin-bottom: 3em;
  }
`
