import React from 'react'
import { GiLoveHowl } from 'react-icons/gi'
import styled from 'styled-components'
import Happyness from './Footer/happyness'
import Info from './Footer/info'
import LanguageSwitch from './LanguageSwitch'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer className="footer">
      <Wrapper>
        <Happyness className="footerItem" />
        <Info className="footerItem" />
      </Wrapper>

      <Legal>
        <LanguageSwitch />

        <Linksout>
          &copy;{new Date().getFullYear()} - fait avec{' '}
          <GiLoveHowl className="colored" /> et
          <br />
          <a href="https://www.inkfs.com" target="_blank" rel="noreferrer">
            Ink and Feather Design Studios
          </a>
        </Linksout>
        <Link to="/legal"  className="whiteout" >
          <br />
          Informations légales
        </Link>
      </Legal>
    </footer>
  )
}

const Wrapper = styled.section`

  @media screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
  }
  width: 90%;
  margin: 2rem 0;
  h4 {
    letter-spacing: 3px;
  }
  h3 {
    color: #0099ff;
    letter-spacing: 10px;
  }

  @media screen and (max-width: 600px) {
    display: block;
    .footer-item {
      float: left;
    }
  }
`

const Legal = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3rem;

  a,
  .colored {
    color: mediumslateblue;
    letter-spacing: 2px;
  }

  .whiteout {
    color: #777;
    font-style: italic;
  }     
  @media screen and (max-width: 600px) {
    display: block;
    .footer-item {
      float: left;
    }
  }
`

const Linksout = styled.section`

`

export default Footer
