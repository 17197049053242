import React from 'react'
import { ImHappy /*ImNeutral, ImSad*/ } from 'react-icons/im'
import styled from 'styled-components'

const happyness = () => {
  return (
    <Wrapper>
      <h3>SATISFACTION</h3>
      <h4>
        Constructeur <ImHappy className="green" />
      </h4>
      <h4>
        Banque <ImHappy className="green" />
      </h4>
      <h4>
        Administration <ImHappy className="green" />
      </h4>
    </Wrapper>
  )
}

export default happyness

const Wrapper = styled.section`
text-align: left;
white-space: nowrap;

.red {
    color: red;
  }
  .green {
    color: green;
  }
  .yellow {
    color: yellow;
  }

}
  `
