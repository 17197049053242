import React from 'react'
import styled from 'styled-components'
const LanguageSwitch = () => {
  return (
    <Wrapper>
      <br />
      <ul>
        <li>
          <a
            href="https://building-with-mca-france.netlify.app/"
            alt="Building a House with Maisons de la Côte Atlantique (MCA) in France - English Website"
            target="_blank"
            rel="noopener noreferrer"
          >
            English
          </a>
        </li>
        <li>
          <a
            href="https://hausbau-mit-mca-frankreich.netlify.app/"
            alt="Hausbau mit Maisons de la Côte Atlantique (MCA) in Frankreich - Deutsche Seite"
            target="_blank"
            rel="noopener noreferrer"
          >
            Deutsch
          </a>
        </li>
      </ul>
    </Wrapper>
  )
}

export default LanguageSwitch

const Wrapper = styled.section`
  li {
    float: left;
    margin: 0 1rem 1rem;
  }
`
