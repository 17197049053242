import React from 'react'
import { FaTwitterSquare } from 'react-icons/fa'
import { MdLanguage } from 'react-icons/md'


const SocialEtc = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <a href="https://twitter.com/mca_experience" target="_blank" alt="Nos expériences avec MCA sur Twitter" rel="noreferrer">
          <FaTwitterSquare className="social-icon current-icon"></FaTwitterSquare>
        </a>
      </li>
      <li>
        <a href="selection-langage" alt="Change Langage vers : Deutsch / English">
          <MdLanguage className="social-icon not-current-icon"></MdLanguage>
        </a>
      </li>
    </ul>
  )
}
export default SocialEtc
