import React from 'react'
import { Link } from 'gatsby'
import { FaBars } from 'react-icons/fa'
import { GiCastle } from 'react-icons/gi'
import Links from '../constants/links'
import SocialEtc from '../constants/socialEtc'

const Navbar = ({ toggle }) => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/" className="nav-logo">
            <GiCastle
              width="200px"
              height="200px"
              alt="Logo - Construction d'une maison avec Maisons de la Côte Atlantique (MCA) en France"
            />
            <button className="toggle-btn" onClick={toggle}>
              <FaBars />
            </button>
          </Link>
        </div>
        <Links styleClass="nav-links" />
        <SocialEtc styleClass="nav-icons" />
      </div>
    </nav>
  )
}

export default Navbar
